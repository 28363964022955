/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MM/DD/YYYY")
            version
            description
          }
        }
      }
    }
  `)
  
  const versions = data.allMarkdownRemark.nodes

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Release Notes`} />
      <div
        style={{
          margin: `0 auto`,
          // maxWidth: 960,
          borderLeft:`solid 1px #eee`,
          borderTop:`solid 1px #eee`,
          backgroundColor:`white`
        }}
      >
        <div className="flex-grid mobile-reverse">
          <div className="left-nav col">
            <nav>
              <h3 className="section-header">ALL VERSIONS</h3>
              <ol style={{ listStyle: `none`, marginLeft:0, marginBottom:0 }}>
                {versions.map(version => {
                  const title = version.frontmatter.version || version.fields.slug

                  return (
                    <li key={version.fields.slug}>
                      <div style={{display:"flex"}}>
                          <Link style={{flex:1}} to={version.fields.slug} itemProp="url" className="nav-link">
                            <span itemProp="headline">{title}</span>
                          </Link>
                        <small>{version.frontmatter.date}</small>
                      </div>
                    </li>
                  )
                })}
              </ol>
            </nav>
          </div>
          <main className="col">{children}</main>
        </div>
        <footer>
          <div className="flex-grid">
            <div className="col">
              <a href="https://www.connectria.com" target="_blank" rel="noreferrer">
                <img width="200px" src="https://www.connectria.com/wp-content/themes/fabric/assets/img/connectria-logo_all-white.png" alt="Connectria"/>
              </a>
              <p>Helping businesses realize their cloud vision with end-to-end hosting and managed services—from IBM i to AWS. Backed by industry leading SLAs and an award winning, customer-centric culture.</p>
            </div>
            <div className="col">
              <ul>
                <li><a href="https://www.connectria.com/services/managed-hosting" target="_blank" rel="noreferrer">MANAGED HOSTING</a></li>
                <li><a href="https://www.connectria.com/services/managed-services" target="_blank" rel="noreferrer">CLOUD MIGRATION &amp; MANAGEMENT</a></li>
                <li><a href="https://www.connectria.com/technology" target="_blank" rel="noreferrer">PLATFORMS</a></li>
                <li><a href="https://www.connectria.com/services/managed-services-for-business-continuity" target="_blank" rel="noreferrer">BUSINESS SERVICES</a></li>
              </ul>
            </div>
            <div className="col">
              <ul>
                <li><a href="https://www.connectria.com/resources" target="_blank" rel="noreferrer">RESOURCES</a></li>
                <li><a href="https://www.connectria.com/about" target="_blank" rel="noreferrer">ABOUT</a></li>
                <li><a href="https://www.connectria.com/about/business-partners" target="_blank" rel="noreferrer">BECOME A PARTNER</a></li>
                <li><a href="https://www.connectria.com/about/careers" target="_blank" rel="noreferrer">CAREERS</a></li>
              </ul>
            </div>
          </div>
          <small>© Connectria {new Date().getFullYear()}</small>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
